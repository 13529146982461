
export default {
  transition: 'default',
  mounted() {
    this.$nextTick(() => {
      const body = document.documentElement
      body.classList.add('error-page')
    })
  },
  destroyed() {
    const body = document.documentElement
    body.classList.remove('error-page')
  },
}

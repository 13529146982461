// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/images/pointer.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html{background:#1b1d27}::-webkit-scrollbar{height:6px;width:18px;background:#000}::-webkit-scrollbar-thumb{background:#ff6b00;-webkit-box-shadow:0 1px 2px rgba(0,0,0,.75)}::-webkit-scrollbar-corner{background:#1b1d27}@media (max-width:768px){::-webkit-scrollbar{height:12px;width:10px;background:#000}::-webkit-scrollbar-thumb{background:#ff6b00;-webkit-box-shadow:0 1px 2px rgba(0,0,0,.75)}::-webkit-scrollbar-corner{background:#1b1d27}}a,button,div,i,input,select,textarea{-webkit-tap-highlight-color:transparent!important;outline:none}.pointer{cursor:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),auto}#gbox-media .flex.justify-between.items-center.h-8.px-2.bg-gray-700.text-white.rounded.rounded-b-none button,.flex.justify-between.items-center.h-8.px-2.text-white.rounded.rounded-b-none.bg-yellow-600 button{width:20px!important}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

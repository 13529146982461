// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./icomoon.eot?pvjlce");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./icomoon.eot?pvjlce#iefix");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./icomoon.ttf?pvjlce");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./icomoon.woff?pvjlce");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./icomoon.svg?pvjlce#icomoon");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#icomoon" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"icomoon\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\");font-weight:400;font-style:normal;font-display:block}[class*=\" icon-\"],[class^=icon-]{font-family:\"icomoon\"!important;speak:never;font-style:normal;font-weight:400;font-feature-settings:normal;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.icon-search:before{content:\"\\e90b\"}.icon-arrow-right:before{content:\"\\e900\"}.icon-arrow-up:before{content:\"\\e90a\"}.icon-down:before{content:\"\\e901\"}.icon-left:before{content:\"\\e902\"}.icon-right:before{content:\"\\e903\"}.icon-instagram:before{content:\"\\e904\"}.icon-linked:before{content:\"\\e905\"}.icon-twitter:before{content:\"\\e906\"}.icon-facebook:before{content:\"\\e907\"}.icon-arrow-left:before{content:\"\\e908\"}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

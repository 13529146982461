
export default {
  data() {
    return {
      menuOpen: false,
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen

      if (this.menuOpen) this.$emit('onMenuOpen')
      if (!this.menuOpen) this.$emit('onMenuClose')
    },
  },
}

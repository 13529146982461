export default {
  state() {
    return {
      categories: [],
      activeCategory: null,
    }
  },
  mutations: {
    setCategories(state, newCategories) {
      state.categories = newCategories
    },
    setActiveCategory(state, newCategory) {
      state.activeCategory = newCategory
    },
  },
  actions: {
    updateCategories({ commit }, categoires) {
      commit('setCategories', categoires)
    },
    updateActiveCategory({ commit }, category) {
      commit('setActiveCategory', category)
    },
  },
}


import * as vClickOutside from 'v-click-outside-x'
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      stickyState: false,
      langDropdown: false,
      showNav: false,
    }
  },
  mounted() {
    const header = this.$refs.theHeader.$el
    if (header) {
      window.addEventListener('scroll', (e) => {
        const scrollOffset = e.target.scrollingElement.scrollTop
        if (scrollOffset > 700) {
          this.stickyState = true
        } else {
          this.stickyState = false
        }
      })
    }
  },
  methods: {
    hideNav() {
      const burger = this.$refs.hamburger
      burger.menuOpen = false
      this.showNav = false
    },
    onClickOutside() {
      this.langDropdown = false
    },
  },
}

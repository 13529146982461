import Vue from 'vue'
import snakeCase from 'lodash/snakeCase'
import { Page } from '@gauss/cms-shared'
import consola from 'consola'

function generateCmsMediaUrl({
  ctx,
  id,
  driveId,
  title,
  extension = 'jpg',
  size,
  hash = '',
}) {
  const mediaTitle = title || 'no_title'
  const formatedTitle = snakeCase(mediaTitle).replace(/_/g, '-')
  const apiKey = ctx.$application.domain.apiKeyId
  // If dev mode then format different url and use app pages route to display media due redirect
  // 09.11.2022, hash appended

  let mediaRedirectUrl = `/media/${apiKey}/${
    driveId || id
  }/${size}/${formatedTitle}.${extension}`

  if (hash) {
    mediaRedirectUrl = `${mediaRedirectUrl}/${hash}`
  }
  return encodeURI(mediaRedirectUrl)
}

Vue.mixin({
  data() {
    return {
      isPageComponent: false,
    }
  },
  created() {
    const pageNames = Object.values(this.$Page)
    this.isPageComponent = pageNames.includes(this.$options.name)
  },
  mounted() {
    if (!this.$isProduction) {
      console.log(this.$route, 'core-global-mixin-log')
    }
    // TODO: stronger typing
    this.isPageComponent && this.$nuxt.$emit('page-mounted')
  },
  computed: {
    $isEditPage() {
      return this.$route.query.editPage === 'true'
    },
    $isBackofficeUser() {
      return !!this.$auth.user?.isBackofficeUser
    },
    /**
     * @deprecated
     * @returns {Boolean} if page is in edit mode
     */
    $contentEditableView() {
      consola.warn('Do not use $contentEditableView, use $isInEditMode instead')
      return this.$isInEditMode
    },
    $isInEditMode() {
      return !this.dummyMode && this.$isEditPage && this.$isBackofficeUser
    },
    $Page() {
      return Page
    },
    $siteLogo() {
      return (
        this.$application.storage.cmsOptions?.find((o) => o.key === 'Logo')
          ?.media?.path || ''
      )
    },
  },
  methods: {
    $at(key, locale, values) {
      return this.$t(
        key,
        locale || this.$application.backofficeUserLanguage,
        values
      )
    },
    $currency(v) {
      const { label } = this.$application.currency
      return [Number(v).toFixed(2), label].join(' ')
    },

    $nuxtLink(link = '', pageTypeOrQuery = 'page', query) {
      const type =
        typeof pageTypeOrQuery === 'string' ? pageTypeOrQuery : 'page'
      const types = ['shop', 'product', 'page']
      if (!types.includes(type)) {
        console.error(`$nuxtLink type: ${type} does not exist`)
      }

      const onDefaultLanguage =
        this.$application.language?.code === this.$application.defaultLanguage
      const prefix = type === 'page' ? '' : type
      const language = onDefaultLanguage
        ? ''
        : this.$application.language?.code2
      const normalisedLink = link[0] === '/' ? link.substr(1) : link
      const path =
        '/' + [language, prefix, normalisedLink].filter(Boolean).join('/')
      console.log(path, 'path')
      const realQuery =
        typeof pageTypeOrQuery === 'string' ? query : pageTypeOrQuery

      return { path, query: realQuery }
    },
    $tempFormatMedia({ image, driveId, title, description }) {
      let media = image
      if (!Array.isArray(media)) {
        media = [image]
      }
      return media
        .map((obj) => {
          if (obj) {
            return {
              driveId: obj[driveId],
              title: obj[title] || '',
              description: obj[description] || '',
            }
          }
        })
        .filter(Boolean)
    },
    // Returns path to media or array of all paths to media corresponding to different media format (avif, webp, jpeg, png)
    $cmsMedia({ media, alt = false, customSize = null, extension = 'png' }) {
      // If media doesn't exist or object is empty return empty string
      if (!media) {
        return ''
      }
      let image = media
      // If media exist, check if its array. If not then set it to always be array
      if (!Array.isArray(media)) {
        image = [media]
      }
      if (image && image.length) {
        let selectedImage = image[0] // Since length is greater then 0 select first image from array
        // Check if image has internal object, if so then check if link is external link and return data
        // if (has(selectedImage, 'internal') && !selectedImage.internal) {
        //   if (alt) {
        //     return selectedImage.description || ''
        //   } else {
        //     return selectedImage.url || ''
        //   }
        // }
        // Check if image has default object and based on that select default image if exist
        const findDefault = image.find((obj) => obj.default)
        if (findDefault) {
          selectedImage = findDefault
        }
        // Format image data (driveId is ID from image)
        const {
          id,
          driveId,
          description,
          title,
          size,
          usePublicPath = null,
          alternativePaths = null,
          path = null,
          media: hash,
        } = selectedImage
        // Check if image has selected extension and append to image or fallback to default
        if (selectedImage.extension) {
          extension = selectedImage.extension
        } else {
          const mime = selectedImage.mime || selectedImage.type || ''
          extension = mime.includes('video')
            ? 'mp4'
            : mime.includes('gif')
            ? 'gif'
            : 'png'
        }
        if (alt) {
          return description || ''
        } else if (usePublicPath && path) {
          return Array.isArray(alternativePaths) && alternativePaths.length
            ? alternativePaths
            : path
        } else {
          // Generate URL
          return generateCmsMediaUrl({
            ctx: this,
            id,
            driveId,
            title,
            size: size || customSize || 'original',
            extension,
            hash,
          })
        }
      } else {
        return false
      }
    },
    // Append new query to current queries if exists (Example: this.$router.push({ query: this.$extendQuery({editMode: true}) }) )
    $extendQuery(newQuery) {
      if (!this.$isProduction) {
        console.log(newQuery, 'newQuery')
      }
      return { ...this.$route.query, ...newQuery }
    },

    // Remove query (Example: this.$router.push({ query: this.$removeQueryParam('editMode') }) )
    $removeQueryParam(params) {
      if (!Array.isArray(params)) {
        params = [params]
      }

      const newQuery = Object.assign({}, this.$route.query)

      params.forEach((param) => {
        delete newQuery[param]
      })

      return newQuery
    },
  },
})
